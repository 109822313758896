export const designsData = [
    {
        type: "DESIGN",
        slug: "DWDFLORGLOGO",  
        date: "23 July 2024",
        collection: "Dr. Florg",
        title: "Dr. Florg - A Story Teller for Our Times! The game company mascot.",
        description: "Dr. Florg was a fictitious character that told fantastic stories, which will be coming soon! Card games and online stories that are family friendly and fantastic.",
        images: [
          {uri:"/img/dr-florg/DWDFLORGLOGO/premium-t-shirt.jpg", alt:"Premium T-Shirt"},
          {uri:"/img/dr-florg/DWDFLORGLOGO/magnet.jpg", alt:"Magnet"},
          {uri:"/img/dr-florg/DWDFLORGLOGO/coasters.jpg", alt:"Coasters"},
          {uri:"/img/dr-florg/DWDFLORGLOGO/spiral-notebook.jpg", alt:"Spiral Noteboo"},
        ],
        links: [
          {"name": "RedBubble", "uri": "https://www.redbubble.com/i/t-shirt/Dr-Florg-A-Story-Teller-for-Our-Times-The-game-company-mascot-by-aschwabe/165768930.WFLAH"}
        ]
      },
              //https://www.redbubble.com/i/t-shirt/Dr-Florg-A-Story-Teller-for-Our-Times-The-game-company-mascot-by-aschwabe/165768930.WFLAH
]