//import { Link } from "react-router-dom";
import { Seo } from '../components/Seo';
import "./Designs.css";


// https://www.pluralsight.com/resources/blog/guides/load-and-render-json-data-into-react-components
import { designsData } from '../data/drflorg'

const Thanksgiving = () => {

    let this_collection = "Dr. Florg";

    // get all the categories
    let collection_descr = "";
    designsData.map((data, key) => (
        // only if collection matches
        collection_descr = collection_descr + data.title + "; "
    ));


    return (
    <>

      <Seo
        title={`Disrupt Wardrobe :: ${this_collection}`}
        description={collection_descr}
        type="webapp"
      />

    <div class="container px-4 py-5" id="hanging-icons">
    <h2 class="pb-2 border-bottom">Dr. Florg</h2>
    <p>Dr. Florg is a company that makes role playing card games, and we love their old west, retro, and cyberpunk mashup style.  Check out some of their exclusive swag here, and stay tuned for info about their upcoming games!</p>

    <div class="row g-3 py-5 px-1 row-cols-1 row-cols-lg-1">


    {designsData.map((design, key) => {
                return (
                    <>
                    {/* begin designs */}


                    <div class="p-1 m-1 py-3 col d-flex align-items-start design_tile">
                        <div>
                        <h4 class="fs-2 text-body-emphasis">{design.title}</h4>
                        <div class="design_images_container">

                          {design.images.map((image) => {
                              return (
                                  <>
                                  <img class="design_img" src={image.uri} alt={image.alt} />
                                  </>                            
                              )}
                          )}

                        </div>
                        <p>{design.description}</p>

                        {design.links.map((link) => {
                            return (
                                <>
                                <a class="btn btn-sm btn-info pe-2 me-2 " href={link.uri}>{link.name}</a>
                                </>                            
                            )}
                        )}

                        </div>
                    </div>


                    </>
                )
            })}



    </div>
  </div>





    </>
    )
  };
  
  export default Thanksgiving;