//import { Link } from "react-router-dom";
import "./Home.css";
import { Seo } from '../components/Seo';

const Home = () => {

  return (
    <>


      <Seo
        title="Disrupt Wardrobe (c) 2024 :: Merch for your geek life"
        description="Clothes and swag that lets your inner geek shine.  Tech, Gifts, Hackathons, and more."
        type="webapp"
      />


      <div id="myCarousel" class="carousel slide mb-6" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg>
              <div class="container">
                <div class="carousel-caption text-start">
                  <img class="home_carousel_1_img carousel_right_side" src="/img/home/work-165281234-pullover-sweatshirt.jpg" alt="blah"/>
                  <h1>Reclaiming Geek Culture.</h1>                  
                  <p class="opacity-75 p-left-side">Now that A.I. is here, now people are respecting the techies that <br/>
                  help keep our WiFi up and prevent us from going crazy.  Get your <br/>
                  dope threads here to show that you are a part of the reason for <br/>
                  so many cool apps and technologies.</p>
                  <p><a class="btn btn-lg btn-primary" href="/collections/tech">Tech Collection</a></p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg>
              <div class="container">
                <div class="carousel-caption text-end">
                <img class="home_carousel_2_img carousel_left_side" src="/img/home/dw_not_robot_apron.jpg" alt="blah"/>
                  <h2>We know you are good.<br/>Maybe too good?</h2>                  
                  <p class="p-right-side">We can't have people questioning if you are secretly a robot.<br/>
                  Enhance your wardrobe with t-shirts, hats, stickers and more<br/>
                  to stop the conspiracies. (oh, and also make you look great!)</p>
                  <p><a class="btn btn-lg btn-primary" href="/collections/tech">Tech Collection</a></p>
                </div>
              </div>
            </div>
            {/* <div class="carousel-item">
              <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg>
              <div class="container">
                <div class="carousel-caption text-end">
                  <h1>One more for good measure.</h1>
                  <p>Some representative placeholder content for the third slide of this carousel.</p>
                  <p><a class="btn btn-lg btn-primary" href="/">Browse gallery</a></p>
                </div>
              </div>
            </div> */}
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>



    </>
    )
  };
  
  export default Home;