//import { Link } from "react-router-dom";
import { Seo } from '../components/Seo';

const Page = () => {

    return (
    <>

      <Seo
        title="Disrupt Wardrobe :: About"
        description="change :: disrupt :: life"
        type="webapp"
      />

    <div class="container px-4 py-5" id="hanging-icons">
        <h2 class="pb-2 border-bottom">About Our Team</h2>
        <p>Where Tech Meets Street Style</p>


        <p>We are a collective of entrepreneurs and creatives who believe that clothing should be more than just fabric—it should be a statement, a conversation starter, and a celebration of digital culture. Born from late-night coding sessions and endless meme scrolling, Disrupt Wardrobe emerged as a passion project for tech enthusiasts who wanted to wear their humor and innovation literally on their sleeves. Our founders share a simple vision: to transform the most viral internet moments and tech inside jokes into wearable art that resonates with programmers, gamers, startup warriors, and internet culture aficionados.</p>
        <p>Every design in our collection is meticulously crafted to capture the spirit of modern digital life, and dodesn't make the cut unless it makes enough of our team laugh. From shirts that showcase coding puns to hoodies, pillows, buttons and aprons that reference obscure tech memes, we create apparel that speaks the language of the internet generation. Our design process is collaborative and community-driven, with many of our most popular items born from suggestions and inside jokes shared by our growing community of tech-savvy followers. We work with graphic designers who understand both the nuanced world of technology and the rapid-fire humor of internet culture to ensure each piece is authentic, clever, and stylishly wearable.</p>
        <p>Beyond creating cool clothing, we're building a community of like-minded individuals who celebrate technology, creativity, and the power of shared humor. A portion of our proceeds goes towards supporting tech education initiatives and scholarships for underrepresented groups in technology, because we believe in giving back to the ecosystem that inspired our brand. Whether you're a software engineer, a startup founder, a gamer, or simply someone who appreciates clever design, Disrupt Wardrobe invites you to express your digital identity and join a movement that's changing how the world sees tech culture—one hilarious, innovative piece of clothing at a time.</p>

    </div>


    </>
    )
  };
  
  export default Page;