//import { Link } from "react-router-dom";
import { Seo } from '../components/Seo';
import HubspotForm from 'react-hubspot-form'

const Page = () => {

    return (
    <>

      <Seo
        title="Disrupt Wardrobe :: Contact"
        description="change :: disrupt :: life"
        type="webapp"
      />

    <div class="container px-4 py-5" id="hanging-icons">
        <h2 class="pb-2 border-bottom">Contact Us</h2>

        <HubspotForm
              portalId='47891070'
              formId='68fd433b-c5d9-4969-acd0-2ca5a33cdf40'
              onSubmit={() => console.log('Submit!')}
              onReady={(form) => console.log('Form ready!')}
              loading={<div>Loading...</div>}
          />


    </div>


    </>
    )
  };
  
  export default Page;