export const designsData = [
  {
    type: "DESIGN",
    slug: "DWTHMTTM",  
    date: "21 Jan 2025",
    collection: "Tech",
    title: "Hodlerware - Wear your crypto heart on your... heart?",
    description: "You are a secret society of people in the know with crypto.  But now you want to geek out with your friends.  This is the way.",
    images: [
      {uri:"/img/tech/DWTHMTTM/classic-t-shirt.jpg", alt:"Classic T-Shirt"},
      {uri:"/img/tech/DWTHMTTM/mouse-pad.jpg", alt:"Mouse Pad"},
      {uri:"/img/tech/DWTHMTTM/coasters.jpg", alt:"Drink Coasters"},
    ],
    links: [
      {"name": "RedBubble", "uri": "https://www.redbubble.com/i/t-shirt/Hodlerware-Wear-your-crypto-heart-on-your-heart-by-aschwabe/168002888.WFLAH"},
      {"name": "RedBubble", "uri": "https://www.redbubble.com/i/sticker/Hodlerware-Wear-your-crypto-heart-on-your-heart-by-aschwabe/168002888.EJUG5"}
    ]
  },
  {
      type: "DESIGN",
      slug: "DWCCMWL",  
      date: "23 July 2024",
      collection: "Tech",
      title: "Funny AI Engineer Startup eat, sleep, Automate",
      description: "Perfect for coders, hackers, startup people. Show your aptitude for building sustainable tech! Wear this at the office, your next job interview, role playing game night, to the cafe, concerts or the next hackathon. Code on!",
      images: [
        {uri:"/img/tech/DWTESA/classic-t-shirt2.jpg", alt:"Classic T-Shirt"},
        {uri:"/img/tech/DWTESA/spiral-notebook.jpg", alt:"Spiral Notebook"},
      ],
      links: [
        {"name": "RedBubble", "uri": "https://www.youtube.com/watch?v=6wlYunvuYFc"},
        {"name": "Amazon USA", "uri": "https://podcasters.spotify.com/pod/show/techtonic-lounge/episodes/E11-Demystifying-A-I--for-Business-People-with"},
        {"name": "Teepublic", "uri": "https://www.teepublic.com/t-shirt/69746731-funny-ai-engineer-startup-eat-sleep-automate"}
      ]
    },

    {
        type: "DESIGN",
        slug: "DWTNAR",  
        date: "23 July 2024",
        collection: "Tech",
        title: "Stand out in all the A.I. that is coming",
        description: "Stand out with all your startup entrepreneur and tech friends. Let the world know that your super intelligence is home grown!",
        images: [
            {uri:"/img/tech/DWTNAR/classic-t-shirt2.jpg", alt:"Classic T-Shirt"},
            {uri:"/img/tech/DWTNAR/classic-t-shirt.jpg", alt:"Classic T-Shirt"},
          {uri:"/img/tech/DWTNAR/relaxed-fit-t-shirt.jpg", alt:"Relaxed Fit T-Shirt"},
          {uri:"/img/tech/DWTNAR/mouse-pad.jpg", alt:"Mouse Pad"},
        ],
        links: [
          {"name": "RedBubble", "uri": "https://www.youtube.com/watch?v=6wlYunvuYFc"},
          {"name": "Amazon USA", "uri": "https://podcasters.spotify.com/pod/show/techtonic-lounge/episodes/E11-Demystifying-A-I--for-Business-People-with"},
        ]
      },
   
              
]